.backContent {
  margin-top: 150px;
  background: var(--foreground-rgb);
  // padding: 30px 24px;
  .content {
    text-align: center;

    > .title {
      font-weight: 900;
      padding: 0 170px;
      // font-family: PlayfairDisplay-MediumItalic;
      font-size: 40px;
      // line-height: 1.5;
      color: var(--app-text-color);

      :nth-child(2n) {
        color: var(--app-primary-color);
      }
    }

    > p {
      margin-top: 50px;
      font-weight: 400;
      font-size: 18px;
      color: var(--app-text-60-color);
      line-height: 1.2;
      padding: 0 120px;
    }

    .imgContainer {
      margin-top: 32px;
      height: 150px;
      object-fit: contain;
    }

    > .btnContainer {
      display: flex;
      justify-content: center;
      gap: 24px;
      margin-top: 120px;
      > div {
        display: inline-block;
        position: relative;
        .btn {
          border: none;
          width: 200px;
          padding: 0 50px;
          height: 50px;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .exampleBtn {
          color: var(--app-primary-color);
          background: white;
          border: 1px solid var(--app-primary-color);
        }
        .getStartBtn {
          color: var(--other-text-color);
          background: var(--app-primary-color);
        }

        > .tip {
          position: absolute;
          display: inline-flex;
          transform: rotate(5deg);
          align-items: center;
          justify-content: center;
          top: -20px;
          right: -30px;
          font-size: 12px;
          height: 24px;
          border-radius: 12px;
          padding: 0 5px;
          background-color: #ff5a5a;
          color: var(--other-text-color);
          font-weight: 700;
          &::after {
            content: '';
            position: absolute;
            bottom: -50%;
            left: 50%;
            width: 0;
            height: 0;
            border: 5px solid transparent;
            border-top-color: #ff5a5a;
            transform: translate(-50%, -30%);
            // transform: rotate(5deg);
          }
        }
      }
    }
    > .manyCustomer {
      margin-top: 30px;
      color: #ffffff;

      > .trustedContainer {
        display: inline-block;
        > span:nth-of-type(3) {
          color: #9873ff;
          margin: 0 5px;
        }
      }
    }
  }
}

@media screen and (min-width: 900px) {
  .backContent {
    .content {
      width: 100%;
    }
  }
}

@media screen and (max-width: 900px) {
  .backContent {
    margin-top: 50px;

    .content {
      > .btnContainer {
        text-align: center;
      }
      .title {
        font-size: 36px;
        padding: 0 80px;
      }
      > p {
        padding: 0 90px;
      }
      // > .rightContainer {
      //   display: none;
      // }
    }
  }
}

@media screen and (max-width: 576px) {
  .backContent {
    .content {
      > .title {
        font-size: 32px;
        padding: 0 10px;
      }

      > p {
        margin-top: 15px;
        padding: 1rem;
      }

      > .btnContainer {
        align-items: center;
        margin-top: 67px;
        > div {
          > .btn {
            width: 154px;
            height: 50px;
            gap: 12px;
          }
          .tip {
            right: -10px;
          }
        }

        > .getStartBtn {
          margin-top: 20px;
        }
      }

      > .stepContainer {
        margin-top: 20px;
      }

      > .stepContainer {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        color: var(--app-text-color);

        .liItem {
          display: flex;
          margin-bottom: 10px;

          > .lineConatiner {
            display: flex;
            flex-direction: column;

            align-items: center;
            justify-content: center;
            margin-right: 10px;

            > .line {
              margin: 5px 0 10px 0;
              height: 100%;
              width: 2px;
              border-top: 0px;
              border-left: 2px dashed var(--app-text-color);
            }
          }

          .itemTitle {
            margin-top: 0;
          }
        }
      }

      > .manyCustomer {
        > .trustedContainer {
          margin-top: 10px;
          display: block;
        }
      }
    }
  }
}

